<script>
import Switches from "vue-switches";
import moment from 'moment';
import {
  required,
  url,
  // minLength
} from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import * as cloneDeep from "lodash.clonedeep";
import appConfig from "@/app.config";
import { paymentMethods, notificationMethods } from "@/state/helpers";
import { ADD_WEBHOOK, UPDATE_WEBHOOK, DELETE_WEBHOOK, UPDATE_WEBHOOK_BY_FORM, FETCH_WEBHOOKS } from "@/state/actions.type";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Webhook Settings",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Switches,
    Layout,
    PageHeader,
  },

  data() {
    return {
      apiwebhooks: {
        name: "",
        url: "",
        type: "payment_status",
        status: "ACTIVE",
        uuid: "",
        created_at: "02 Mar, 2020",
        enabled: true,
      },
      submitted: false,
      showmodal: false,
      webhookStoreData: [],
      totalRows: 1,
      currentPage: 1,
      // perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      sortBy: "date",
      sortDesc: true,
      currentWebhookDetail: {
        name: "",
        url: "",
        type: "",
      },
      editWebhookDetail: {
        name: "",
        url: "",
        type:"",
      },
      showEditWebhookModal: false,
      editWebhookFormSubmitted: false,
      fields: [
        { key: "created_at", sortable: true, label: "views.integration.api-settings.webhooks.table.created_at" },
        { key: "name", sortable: true, label: "views.integration.api-settings.webhooks.table.name" },
        { key: "url", sortable: true, label: "views.integration.api-settings.webhooks.table.url" },
        { key: "type", sortable: true, label: "views.integration.api-settings.webhooks.table.type" },
        { key: "enabled", sortable: false, label: "views.integration.api-settings.webhooks.table.enabled" },
        { key: "edit_link", label:"views.integration.api-settings.webhooks.table.edit_link" },
        // { key: "delete_link", label: "Delete" }
        
      ],
      format: function formatCredentials(text){
        const result = text.replace(/_/g, ' ');
        const finalresult = result.toLowerCase()
          .split(' ')
          .map(function(word) {
              return word[0].toUpperCase() + word.substr(1);
          })
          .join(' ');

        return finalresult;
      }
    };
  },

  created() {
    this.$store.dispatch("webhook/fetchWebhooksData");
    this.webhookStoreData = cloneDeep(this.webhookData);
  },
  mounted() {
    // Set the initial number of items
    this.webhookStoreData = cloneDeep(this.webhookData);
    this.totalRows = this.webhookStoreData.length;
  },

  computed: {
    webhookData() {
      this.$store.state.webhook.urls.forEach(element => {
        element.delete_link = element.uuid
        element.edit_link = element.uuid
      });
      return this.$store.state.webhook.urls;
    },
    // isFetchingTransactions() {
    //   return this.$store.state.webhook.isFetchingData;
    // },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    title() {
      return this.$t('views.integration.api-settings.general.general-settings');
    },

    items() {
      return [{
        text: this.$t('menuitems.integration.list.apiconfig'),
        active: true
      },
      {
        text: this.$t('menuitems.integration.list.api-webhooks'),
        active: true,
      }];
    },

    userRoles() {
      return this.$store.state.auth.currentUser.roles;
    },
  },

  validations: {
    apiwebhooks: {
      name: { required },
      type: { required },
      url: { required, url },
      // status: { required },
      // created_date: { required },
    },
    editWebhookDetail: {
      name: { required },
      url: { required, url },
      type: { required },
    },
  },

  filters: {
    formateDate(value) {
      return moment(value).format('MMMM DD YYYY, h:mm:ss a');
    }
  },

  watch: {
    webhookData() {
      this.webhookStoreData = cloneDeep(this.webhookData);
    },
  },

  methods: {
    ...paymentMethods,
    ...notificationMethods,

    toggleSwitch(value, item) {
      if (value != item.enabled) {
        const webhookData = {
          created_at: item.created_at,
          enabled: value,
          name: item.name,
          url: item.url,
          uuid: item.uuid,
        };
        webhookData.enabled = value;
        this.$store
          .dispatch(`webhook/${UPDATE_WEBHOOK}`, webhookData)
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteWebhookLink(linkRef){
      Swal.fire({
        title: this.$t("views.integration.api-settings.webhooks.delete_confirmation"),
        text:"Link Ref - "+ linkRef,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.delete"),
        cancelButtonText: this.$t('button.cancel'),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`webhook/${DELETE_WEBHOOK}`, linkRef)
            .then((response) => {
              console.log(response);
              this.$notify({
                type: "success",
                text: this.$t("views.integration.api-settings.webhooks.delete_success"),
                closeOnClick: true,
              });
              Swal.fire(this.$t("common.deleted"), this.$t("views.integration.api-settings.webhooks.delete_success"), "success");
              setTimeout(() => {
                this.fetchPaymentLinks();
              }, 500);
            })
            .catch((error) => {
              // error
              console.log("error", error);
              Swal.fire(this.$t("common.deleted"), this.$t("views.integration.api-settings.webhooks.delete_failed"), "error");
            });
        }
      });
    },
    updateWebhook() {
      this.editWebhookFormSubmitted = true;

      // stop here if form is invalid
      this.$v.editWebhookDetail.$touch();
      if (this.$v.editWebhookDetail.$invalid) {
        return;
      } else {

        this.$store.dispatch(`webhook/${UPDATE_WEBHOOK_BY_FORM}`, {webhookData: this.editWebhookDetail})
          // eslint-disable-next-line no-unused-vars
          .then((result) => {
            // Added new user
            this.$store.dispatch(`webhook/${FETCH_WEBHOOKS}`)
            this.showEditWebhookModal = false;
          })
          .catch((error) => {
            // Added new user
            console.log(error);
            this.showEditWebhookModal = false;
          });

        this.currentWebhookDetail = {
          name: "",
          url: "",
          type: "",
        };
      }
      this.editWebhookFormSubmitted = false;
    },


    openEditWebhookModal(webhookDetails) {
      this.editWebhookDetail = cloneDeep(webhookDetails);
      this.resetNotificationError();
      this.showEditWebhookModal = true;
    },
    hideEditWebhookModal() {
      this.editWebhookDetail = {
        name: "",
        url: "",
        type: "",
      };
      this.editWebhookFormSubmitted = false;
      this.showEditWebhookModal = false;
    },

    // eslint-disable-next-line no-unused-vars
    addWebhook(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.apiwebhooks.$touch();
      if (this.$v.apiwebhooks.$invalid) {
        return;
      } else {
        const webhookData = {
          name: this.apiwebhooks.name,
          url: this.apiwebhooks.url,
          type: this.apiwebhooks.type,
          enabled: this.apiwebhooks.enabled,
        };
        // this.addWebhookData({ webhookData })
        this.$store
          .dispatch(`webhook/${ADD_WEBHOOK}`, webhookData)
          // eslint-disable-next-line no-unused-vars
          .then((result) => {
            // added webhoook
          })
          .catch((error) => {
            console.log(error);
            // failed while adding webhook
          });
        this.apiwebhooks = {
          name: "",
          url: "",
          status: "ACTIVE",
          uuid: "",
          type: "payment_status",
          created_at: "02 Mar, 2020",
          enabled: true,
        },

        this.showmodal = false;
      }
      this.submitted = false;
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },

    sortByDate(a, b, key) {
      if (key == "date") {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1 - d2;
      }
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    },

    openWebhookModal() {
      this.resetNotificationError();
      this.showmodal = true;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-alert
      variant="danger"
      class="mt-3"
      v-if="notification.message"
      show
      dismissible
      @dismissed="resetNotificationError"
    >{{notification.message}}</b-alert>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <span>
                <b-btn
                  size="sm"
                  variant="primary"
                  @click="openWebhookModal"
                >
                  {{ $t('views.integration.api-settings.webhooks.add-webhooks') }}
                </b-btn>
              </span>
            </div>
            
            <div class="table-responsive text-center table-custom mt-3">
              <b-table
                class="table-centered"
                :items="webhookStoreData"
                :fields="fields"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-compare="sortByDate"
                :bordered="true"
                :small="true"
                :filter-included-fields="filterOn"
                sticky-header="500px"
                show-empty
                :empty-text="$t('common.no-records')"
                busy.sync="true"
                hover
              >
              <template v-slot:head()="data">
                  {{ $t(data.label) }}
                </template>
              <template v-slot:cell(created_at)="row">
                  
                  <div v-show="row.value" class="d-flex justify-content-center align-items-center">
                      <p class="mb-0">{{ row.value | formateDate }}</p>
                      
                  </div>
                </template>
                <template v-slot:cell(type)="row">
                  <div v-show="row.value" class="d-flex justify-content-center align-items-center">
                      <p class="mb-0">{{ format(row.value) }}</p>   
                  </div>
                </template>
                <template v-slot:cell(enabled)="{ value, item }">
                  
                  <div v-show="value" class="d-flex justify-content-center align-items-center">
                      <switches
                        :value="item.enabled"
                        color="primary"
                        class="ml-1 font-size-10"
                        @input="toggleSwitch($event, item)"
                      ></switches>
                        
                  </div>
                </template>

                <template v-slot:cell(edit_link)="row">
                  <span v-if="row.value" class="btn btn-sm btn-dark" @click="openEditWebhookModal(row.item)">{{ $t("button.edit") }}</span>
                </template>
                
                
                <!-- <template v-slot:cell(delete_link)="row">
                  <span v-if="row.value" class="btn btn-sm btn-danger" @click="deleteWebhookLink(row.value)">Delete</span>
                </template> -->
              </b-table>
              <!-- <Spinner v-if="isFetchingTransactions === true"></Spinner> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      :title="$t('views.integration.api-settings.webhooks.add-webhooks')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="addWebhook">
        <div class="form-group">
          <label for="name">{{ $t('views.integration.api-settings.webhooks.name') }}<span class="text text-danger">*</span></label>
          <input
            id="name"
            v-model="apiwebhooks.name"
            type="text"
            class="form-control"
            :placeholder="$t('views.integration.api-settings.webhooks.unique-name')"
            :class="{ 'is-invalid': submitted && $v.apiwebhooks.name.$error }"
          />
          <div
            v-if="submitted && !$v.apiwebhooks.name.required"
            class="invalid-feedback"
          >
            {{ $t('views.integration.api-settings.webhooks.validation.name') }}
          </div>
        </div>

        <div class="form-group">
          <label for="url">{{ $t('views.integration.api-settings.webhooks.url') }}<span class="text text-danger">*</span></label>
          <input
            id="url"
            v-model="apiwebhooks.url"
            type="url"
            class="form-control"
            placeholder="https://mycompanysite.com"
            :class="{ 'is-invalid': submitted && $v.apiwebhooks.url.$error }"
          />
          <div
            v-if="submitted && !$v.apiwebhooks.url.required"
            class="invalid-feedback"
          >
            {{ $t('views.integration.api-settings.webhooks.validation.url') }}
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('views.integration.api-settings.webhooks.type') }}<span class="text text-danger">*</span></label>
          <b-form-select
            name="type"
            v-model="apiwebhooks.type"
            class="form-control"
          >
            <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="payment_status">{{ $t('views.integration.api-settings.webhooks.status.payment') }}</b-form-select-option>
            <b-form-select-option v-if="userRoles.includes('payouts-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="payout_status">{{ $t('views.integration.api-settings.webhooks.status.payout') }}</b-form-select-option>
            <b-form-select-option v-if="userRoles.includes('payment-refund-user') || userRoles.includes('payouts-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="refund_status">{{ $t('views.integration.api-settings.webhooks.status.refund') }}</b-form-select-option>
            <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="onboarding_status">{{ $t('views.integration.api-settings.webhooks.status.onboarding') }}</b-form-select-option>
            <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="payment_link_status">{{ $t('views.integration.api-settings.webhooks.status.payment_link') }}</b-form-select-option>
            <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="subscription_status">Subscription Status</b-form-select-option>
          </b-form-select>
        </div>

        <div class="text-right">
          <b-button variant="light" class="mr-2" @click="hideModal">{{ $t('button.close') }}</b-button>
          <b-button type="submit" variant="primary" class="ml-1">{{ $t('button.save') }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->

    <!-- Edit Webhook Modal -->
    <b-modal
      size="md"
      id="edit-webhook-modal"
      v-model="showEditWebhookModal"
      :title="'Edit Webhook Details'"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="updateWebhook">
              <div class="form-group">
                <label for="name">{{ $t('views.integration.api-settings.webhooks.name') }}<span class="ml-1 text text-danger">*</span></label>
                <input
                  id="webhookname"
                  v-model="editWebhookDetail.name"
                  type="text"
                  class="form-control"
                  :placeholder="$t('views.integration.api-settings.webhooks.unique-name')"
                  :class="{ 'is-invalid': editWebhookFormSubmitted && $v.editWebhookDetail.name.$error }"
                />
                <div
                  v-if="editWebhookFormSubmitted && !$v.editWebhookDetail.name.required"
                  class="invalid-feedback"
                >
                  {{ $t('views.integration.api-settings.webhooks.validation.name') }}
                </div>
              </div>
            
            
              <div class="form-group">
                <label for="url">{{ $t('views.integration.api-settings.webhooks.url') }}</label>
                <input
                  id="webhookurl"
                  v-model="editWebhookDetail.url"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': editWebhookFormSubmitted && $v.editWebhookDetail.url.$error }"
                />
                <div
                  v-if="editWebhookFormSubmitted && !$v.editWebhookDetail.url.required"
                  class="invalid-feedback"
                >
                  {{ $t('views.integration.api-settings.webhooks.validation.url') }}
                </div>
            </div>
            
              <div class="form-group">
                <label for='type'>{{ $t('views.integration.api-settings.webhooks.type') }}<span class="text text-danger">*</span></label>
                <b-form-select
                  id="webhooktype"
                  name="type"
                  disabled
                  v-model="editWebhookDetail.type"
                  class="form-control"
                >
                  <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="payment_status">{{ $t('views.integration.api-settings.webhooks.status.payment') }}</b-form-select-option>
                  <b-form-select-option v-if="userRoles.includes('payouts-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="payout_status">{{ $t('views.integration.api-settings.webhooks.status.payout') }}</b-form-select-option>
                  <b-form-select-option v-if="userRoles.includes('payment-refund-user') || userRoles.includes('payouts-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="refund_status">{{ $t('views.integration.api-settings.webhooks.status.refund') }}</b-form-select-option>
                  <b-form-select-option v-if="userRoles.includes('payment-admin') || userRoles.includes('admin') || userRoles.includes('sub-admin')" value="onboarding_status">{{ $t('views.integration.api-settings.webhooks.status.onboarding') }}</b-form-select-option>
                </b-form-select>
              </div>
            
        
        <div class="text-right">
          <b-button variant="light" @click="hideEditWebhookModal">{{ $t('button.close') }}</b-button>
          <button type="submit" class="btn btn-primary ml-2">{{ $t('button.update') }}</button>
        </div>
        

      </form>
    </b-modal>

  </Layout>
</template>
